body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
}
/* * {
  border: red solid 1px;
} */
.iiz__close::before {
  background-size: 100% 4px, 4px 100% !important;
  width: 39px !important;
  height: 39px !important;
}
